import React from 'react';
import {Switch,  Route, Redirect} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'font-awesome/css/font-awesome.min.css';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'; // Archivo CSS de Bootstrap 5
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';

// Archivos de Menu y Footer

import Menu from './Componentes/Cabecera/Menu'; 
import Footer from './Componentes/Footer/Footer'; 



// Páginas del Sitio Web
import Home from './Componentes/Home/Home'; 
import AvisoLegal from './Componentes/AvisoLegal/AvisoLegal'; 
import PoliticaPrivacidad from './Componentes/PoliticaPrivacidad/PoliticaPrivacidad';
import Cookies from './Componentes/Cookies/Cookies';
import Contacto from './Componentes/Contacto/Contacto';
import Blog from './Componentes/Blog/Blog';


// Especialidades
import Civil from './Componentes/Especialidades/Civil';
import Familia from './Componentes/Especialidades/Familia';
import Contratos from './Componentes/Especialidades/Contratos';
import Hipotecas from './Componentes/Especialidades/Hipotecas';
import Propiedad_Horizontal from './Componentes/Especialidades/Propiedad_Horizontal';
import Vicios_Constructivos from './Componentes/Especialidades/Vicios_Contructivos';
import Procesal from './Componentes/Especialidades/Procesal';
import Nuevas_Tecnologias from './Componentes/Especialidades/Nuevas_Tecnologias';
import Administrativo from './Componentes/Especialidades/Administrativo';

// Post Blog
import Post_Horizontal from './Componentes/Blog/Post_Horizontal';
import Post_Conflicto from './Componentes/Blog/Post_Conflicto';
import Post_Testamento from './Componentes/Blog/Post_Testamento';
import Post_Smart from './Componentes/Blog/Post_Smart';
import Post_Covid from './Componentes/Blog/Post_Covid';
import Post_Ertes from './Componentes/Blog/Post_Ertes';
import Post_Arrendamientos from './Componentes/Blog/Post_Arrendamientos';
import Post_New_Horizontal from './Componentes/Blog/Post_New_Horizontal';
import Post_Mascotas from './Componentes/Blog/Post_Mascotas';
import Post_Cuantia_Pension from './Componentes/Blog/Post_Cuantia_Pension';
import Post_Medidas_Discapacidad from './Componentes/Blog/Post_Medidas_Discapacidad';
import Post_Tipo_Divorcios from './Componentes/Blog/Post_Tipo_Divorcios';
import Post_Vivienda_Heredada from './Componentes/Blog/Post_Vivienda_Heredada';
import Post_Vivienda_Divorcio from './Componentes/Blog/Post_Vivienda_Divorcio';
import Post_Reclamacion_Factura from './Componentes/Blog/Post_Reclamacion_Factura';
import Post_Incumplimiento_Visitas from './Componentes/Blog/Post_Incumplimiento_Visitas';
import Post_Inteligencia_Artificial from './Componentes/Blog/Post_Inteligencia_Artificial';
import Post_Vicios_Constuctivos from './Componentes/Blog/Post_Vicios_Constuctivos';
import Post_Claves_Alquiler from './Componentes/Blog/Post_Claves_Alquiler';
import Post_Contrato_Arras from './Componentes/Blog/Post_Contrato_Arras';
import Post_Herederos_Menores from './Componentes/Blog/Post_Herederos_Menores';
import Post_Nueva_Ley_Vivienda from './Componentes/Blog/Post_Nueva_Ley_Vivienda';
import Post_Pension_Verano from './Componentes/Blog/Post_Pension_Verano';
import Post_Divorcio_Hijos from './Componentes/Blog/Post_Divorcio_Hijos';
import Post_Cargo_Tarjeta from './Componentes/Blog/Post_Cargo_Tarjeta';
import Post_Gastos_Universidad from './Componentes/Blog/Post_Gastos_Universidad';
import Post_Precario from './Componentes/Blog/Post_Precario';
import Post_Colegio from './Componentes/Blog/Post_Colegio';
import Post_Dinero_Privativo from './Componentes/Blog/Post_Dinero_Privativo';
import Post_Cuestiones_Compraventa from './Componentes/Blog/Post_Cuestiones_Compraventa';
import Post_Sentencia_Extranjera_Divorcio from './Componentes/Blog/Post_Sentencia_Extranjera_Divorcio';
import Post_IBI from './Componentes/Blog/Post_IBI';


// Configuración de la rutas del Sitio Web 
class App extends React.Component {

  componentDidMount() {
		const script = document.createElement("script");

    require('no-pollution');
		script.src = "https://code.jquery.com/jquery-3.6.0.min.js";
		script.src = "/assets/js/custom.js";
		script.async = true;
	
		document.body.appendChild(script);
	  }
  render() {

    const Paths = {

      Civil: '/areas-legales/derecho-civil',
      Familia: '/areas-legales/familia-sucesiones',
      Contratos: '/areas-legales/arrendamientos-contratos',
      Hipotecas: '/areas-legales/hipotecas',
      Propiedad_Horizontal: '/areas-legales/propiedad-horizontal',
      Vicios_Constructivos: '/areas-legales/vicios-constructivos',
      Procesal: '/areas-legales/derecho-procesal-civil',
      Nuevas_Tecnologias: '/areas-legales/nuevas-tecnologias',
      Administrativo: '/areas-legales/derecho-tramites-administrativos' 
   }

    return (
      <div className="App">

    
        <Menu /> 
        <Switch>
          {/* Páginas */}
          <Route exact path='/' component = {Home} /> 
          <Route path='/avisolegal' component = {AvisoLegal}/>
          <Route path='/politicaprivacidad' component = {PoliticaPrivacidad}/>
          <Route path='/cookies' component = {Cookies}/>
          <Route path='/contacto' component={Contacto} /> 
          
          <Route path={Paths.Civil} component={Civil}/>
          <Route path={Paths.Familia} component={Familia}/>
          <Route path={Paths.Contratos} component={Contratos}/>
          <Route path={Paths.Hipotecas} component={Hipotecas}/>
          <Route path={Paths.Propiedad_Horizontal} component={Propiedad_Horizontal}/>
          <Route path={Paths.Vicios_Constructivos} component={Vicios_Constructivos}/>
          <Route path={Paths.Procesal} component={Procesal}/>
          <Route path={Paths.Nuevas_Tecnologias} component={Nuevas_Tecnologias}/>
          <Route path={Paths.Administrativo} component={Administrativo}/>

          <Route exact path='/blog' component={Blog} /> 
          <Route path='/blog/post-propiedad-horizontal' component={Post_Horizontal} /> 
          <Route path='/blog/post-patria-potestad' component={Post_Conflicto} /> 
          <Route path='/blog/post-testamento' component={Post_Testamento} /> 
          <Route path='/blog/post-smart-contract' component={Post_Smart} /> 
          <Route path='/blog/post-covid-arrendamientos' component={Post_Covid} /> 
          <Route path='/blog/post-erte' component={Post_Ertes} /> 
          <Route path='/blog/post-novedades-arrendamientos' component={Post_Arrendamientos} /> 
          <Route path='/blog/post-novedades-propiedad-horizontal' component={Post_New_Horizontal} /> 
          <Route path='/blog/post-divorcio-mascotas' component={Post_Mascotas} /> 
          <Route path='/blog/post-pension-alimentos' component={Post_Cuantia_Pension} /> 
          <Route path='/blog/post-medidas-de-apoyo-a-personas-con-discapacidad' component={Post_Medidas_Discapacidad} /> 
          <Route path='/blog/post-tipos-de-divorcios' component={Post_Tipo_Divorcios} /> 
          <Route path='/blog/post-vivienda-heredada' component={Post_Vivienda_Heredada} /> 
          <Route path='/blog/post-vivienda-divorcio' component={Post_Vivienda_Divorcio} /> 
          <Route path='/blog/post-reclamacion-factura' component={Post_Reclamacion_Factura} /> 
          <Route path='/blog/post-incumplimiento-regimen-visitas' component={Post_Incumplimiento_Visitas} /> 
          <Route path='/blog/post-inteligencia-artificial' component={Post_Inteligencia_Artificial} /> 
          <Route path='/blog/post-vicios-constructivos' component={Post_Vicios_Constuctivos} /> 
          <Route path='/blog/post-claves-contratos-alquiler' component={Post_Claves_Alquiler} /> 
          <Route path='/blog/post-contratos-arras' component={Post_Contrato_Arras} /> 
          <Route path='/blog/post-heredero-menor-de-edad' component={Post_Herederos_Menores} /> 
          <Route path='/blog/post-nueva-ley-vivienda' component={Post_Nueva_Ley_Vivienda} /> 
          <Route path='/blog/post-pension-alimentos-vacaciones' component={Post_Pension_Verano} /> 
          <Route path='/blog/post-divorcio-contacto-hijos' component={Post_Divorcio_Hijos} /> 
          <Route path='/blog/post-cargo-tarjeta-no-autorizado' component={Post_Cargo_Tarjeta} /> 
          <Route path='/blog/post-gastos-universidad-en-divorcio' component={Post_Gastos_Universidad} /> 
          <Route path='/blog/post-precario' component={Post_Precario} /> 
          <Route path='/blog/post-padres-separados-colegio' component={Post_Colegio} /> 
          <Route path='/blog/post-dinero-privativo-en-cuenta-ganancial' component={Post_Dinero_Privativo} /> 
          <Route path='/blog/post-cuestiones-compraventa' component={Post_Cuestiones_Compraventa} /> 
          <Route path='/blog/post-sentencia-extranjera-de-divorcio' component={Post_Sentencia_Extranjera_Divorcio} /> 
          <Route path='/blog/post-ibi-pendiente-compraventa' component={Post_IBI} /> 
          <Redirect to="/"/>
      </Switch>
      <Footer /> 
    </div>
 
    );
  }
}
export default App;
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

