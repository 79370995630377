import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_IBI extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>IBI pendiente de pago en una compraventa, ¿quién debe abonarlo?</title>
				<meta name="description" content="IBI pendiente de pago en una compraventa, ¿quién debe abonarlo?" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-ibi-pendiente-compraventa" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="IBI pendiente de pago en una compraventa, ¿quién debe abonarlo?" />
				<meta property="og:description" content="IBI pendiente de pago en una compraventa, ¿quién debe abonarlo?" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-ibi-pendiente-compraventa" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="IBI pendiente de pago en una compraventa, ¿quién debe abonarlo?" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>IBI pendiente de pago en una compraventa, ¿quién debe abonarlo?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Diciembre 9, 2024 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> La compra de una vivienda es una de las decisiones más importante en la vida de una persona, son muchas las cuestiones a tener en cuenta para evitar problemas, y una de ellas es que el vendedor se encuentre al corriente de pago del Impuesto sobre Bienes Inmuebles, pero ¿qué ocurre si esto no es así?
                        <br/></p>
                        <img className="img-fluid img-post" src="/assets/images/post_ibi.webp" width="100%" height="100%" alt="IBI pendiente de pago en una compraventa, ¿quién debe abonarlo?"/>
                        <span className="caption text-muted">Garlaw Abogados. IBI pendiente de pago en una compraventa, ¿quién debe abonarlo?</span>
                        <p>
                        
                        Muchos clientes acuden a nuestro despacho preguntando quién asumirá los importes pendientes del IBI en una compraventa, si ellos como compradores están obligados a su abono.
                        <br/><br/>
                        Desde Garlaw Abogados siempre aconsejamos contar desde el inicio con un asesoramiento especializado para evitar problemas a futuro, no obstante, te aclaramos algunas de estas cuestiones al respecto.
                        <br/><br/>
                        En primer lugar, es necesario aclarar que el Impuesto de Bienes Inmuebles es unos de los tributos que paga anualmente el propietario de un inmueble, por eso a la hora de comprar una vivienda es importante verificar que el vendedor se encuentra al corriente de pago del mismo, pues de lo contrario, nosotros como compradores nos veremos obligados a asumir las deudas pendientes que no estuvieran prescritas.
                        <br/><br/>
                        Desde Garlaw Abogados, siempre aconsejamos que se aporte en la escritura de compraventa un certificado expedido por el Ayuntamiento en el que coste que el vendedor se encuentra al corriente del pago del impuesto. En caso de no poder contar con dicho certificado, que se aporte por el vendedor los cuatro últimos justificantes de pago del impuesto, garantizando con ello que el inmueble se entrega libre de cargas y gravámenes y al corriente de todas sus obligaciones fiscales.
                        <br/><br/>
                        Pero ¿qué ocurre si hay impago del IBI por el vendedor?
                        <br/><br/>
                        De conformidad con el derecho de afección establecido en el artículo 79 de la Ley General Tributaria, el comprador se convierte en el responsable subsidiario de las deudas del IBI frente a la Administración Local, pero siempre y cuando no se haya podido cobrar al vendedor y sin perjuicio de que posteriormente el comprador pueda ejercer el derecho de repetición contra el vendedor.
                        <br/><br/>
                        En consecuencia, el comprador asume responsabilidad en cuanto al pago del mismo, de ahí la importancia de solicitar la documentación pertinente para evitar sorpresas.
                        <br/><br/>
                        Y respecto al año en curso ¿quién debe abonar el IBI?
                        <br/><br/>
                        Deberá abonarlo la persona que sea propietario del mismo a 1 de enero del año en que se va a realizar el pago, no obstante, es posible pactar entre las partes que el comprador asumo el pago proporcional del IBI al tiempo de adquisición de la vivienda. 
                        <br/><br/>
                        Como se puede observar, son muchas las cuestiones a tener en cuenta en una compraventa, es por eso, por lo que siempre aconsejamos contar desde el inicio con un asesoramiento legal y especializado en  Derecho Inmobiliario, ya que con ello se pueden evitar problemas mayores.   
                        <br/><br/>
                        Si tienes más dudas sobre este tema o quieres que analicemos tu caso en concreto, no dudes en ponerte en contacto con nosotros. Te estamos esperando.
                       </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                        <li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/7PI2zR3oCLM"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7272273536038109186/?actorCompanyId=82083167"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados/videos/4070926769808871"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://x.com/garlawabogados/status/1866507307372236932"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_IBI);